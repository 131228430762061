// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-component {
  min-height: 50px;
  padding-top: 75px;
  padding-bottom: 25px;
  color: #ffffff;
  background-color: #000000;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
  font-size: 14px;
}
.footer-component .footer-logo {
  max-height: 100px;
  width: auto;
}
.footer-component .footer-links ul {
  list-style: none;
  padding: 0px;
}
.footer-component .footer-links ul li {
  line-height: 20px;
  cursor: pointer;
}
.footer-component .footer-contact ul {
  list-style: none;
  padding: 0px;
}
.footer-component .footer-contact ul li {
  line-height: 20px;
}
@media only screen and (max-width: 991px) {
  .footer-component .footer-links {
    margin-top: 30px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/footer/Footer.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,iBAAA;EACA,oBAAA;EACA,cAAA;EACA,yBAAA;EACA,uDAAA;EACA,eAAA;AACJ;AAEI;EACG,iBAAA;EACA,WAAA;AAAP;AAIQ;EACI,gBAAA;EACA,YAAA;AAFZ;AAIY;EACI,iBAAA;EACA,eAAA;AAFhB;AAQQ;EACI,gBAAA;EACA,YAAA;AANZ;AAQY;EACI,iBAAA;AANhB;AAWI;EACI;IACI,gBAAA;EATV;AACF","sourcesContent":[".footer-component {\n    min-height: 50px;\n    padding-top: 75px;\n    padding-bottom: 25px;\n    color: #ffffff;\n    background-color: #000000;\n    box-shadow: 0px 4px 4px 0px #00000040;\n    font-size: 14px;\n\n\n    .footer-logo {\n       max-height: 100px;\n       width: auto;\n    }\n\n    .footer-links {\n        ul {\n            list-style: none;\n            padding: 0px;\n\n            li {\n                line-height: 20px;\n                cursor: pointer;\n            }\n        }\n    }\n\n    .footer-contact {\n        ul {\n            list-style: none;\n            padding: 0px;\n\n            li {\n                line-height: 20px;\n            }\n        }\n    }\n\n    @media only screen and (max-width: 991px) {\n        .footer-links {\n            margin-top: 30px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
