// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-us-form .container {
  padding-left: 0px;
  padding-right: 0px;
}
.contact-us-form .form-label {
  color: #000000 !important;
  font-size: 14px;
}
.contact-us-form .form-control {
  border: 0px solid #8E7936;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
  font-family: "Calibri", sans-serif;
  font-size: 14px;
}
.contact-us-form .form-control.form-textarea {
  min-height: 200px;
}
.contact-us-form .form-select {
  border: 2px solid #8E7936;
}`, "",{"version":3,"sources":["webpack://./src/components/contact-us-form/ContactUsForm.scss"],"names":[],"mappings":"AAEI;EACI,iBAAA;EACA,kBAAA;AADR;AAII;EACI,yBAAA;EACA,eAAA;AAFR;AAKI;EACI,yBAAA;EACA,uDAAA;EACA,kCAAA;EACA,eAAA;AAHR;AAKQ;EACI,iBAAA;AAHZ;AAOI;EACI,yBAAA;AALR","sourcesContent":[".contact-us-form {\n\n    .container {\n        padding-left: 0px;\n        padding-right: 0px;\n    }\n\n    .form-label {\n        color: #000000 !important;\n        font-size: 14px;\n    }\n\n    .form-control {\n        border: 0px solid #8E7936;\n        box-shadow: 0px 4px 4px 0px #00000040;\n        font-family:\"Calibri\", sans-serif;\n        font-size: 14px;\n\n        &.form-textarea {\n            min-height: 200px;\n        }\n    }\n\n    .form-select {\n        border: 2px solid #8E7936;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
