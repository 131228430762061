import React, { Fragment, useEffect, useState, useMemo, useRef } from 'react';

const NumberSpinner = ({ value = 0, max = 10, inc = 10, prefix = "", suffix ="" }) => {
    const ref1 = useRef(null);
    const isInViewport1 = useIsInViewport(ref1);

    const [currentSlide, setCurrentSlide] = useState(value);

    const autoScrollInterval = 60; // Auto-scroll interval in milliseconds

    useEffect(() => {
        const interval = setInterval(nextSlide, autoScrollInterval);

        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSlide, isInViewport1]);

    const nextSlide = () => {
        if (currentSlide < max && isInViewport1)
        setCurrentSlide(currentSlide+inc);
    };

    return (
       <Fragment>
        <div ref={ref1}>
            {prefix}{currentSlide?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{suffix}
        </div>
       </Fragment>
    );
}

export default NumberSpinner;

const useIsInViewport = (ref) => {
    const [isIntersecting, setIsIntersecting] = useState(false);
  
    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting),
        ),
      [],
    );
  
    useEffect(() => {
      observer.observe(ref.current);
  
      return () => {
        observer.disconnect();
      };
    }, [ref, observer]);
  
    return isIntersecting;
  }