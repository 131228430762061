// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Anton&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  scroll-behavior: smooth;
}

body {
  font-family: "Anton", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
  font-size: 14px;
  background-color: #ffffff;
}

p {
  font-family: "Calibri", sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAEA;EACE,uBAAA;AAAF;;AAGA;EACE,gCAAA;EACA,mCAAA;EACA,kCAAA;EACA,cAAA;EACA,eAAA;EACA,yBAAA;AAAF;;AAGA;EACE,kCAAA;AAAF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');\n\nhtml {\n  scroll-behavior: smooth;\n}\n\nbody {\n  font-family: 'Anton', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  color: #000000;\n  font-size: 14px;\n  background-color: #ffffff;\n}\n\np {\n  font-family:\"Calibri\", sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
