import React, { Fragment, useState } from 'react';
import Scroll from 'react-scroll';
import './Navbar.scss';
import Logo from '../../logo.svg';
import { trackPageView } from '@snowplow/browser-tracker';

const Navbar = () => {
    const [show, setShow] = useState('navbar-collapse collapse');

    const onBurgerMenuClick = (oneSide = false) => {
        if (window?.innerWidth <= 991) {
            if (show === 'navbar-collapse collapse' && !oneSide) {
                setShow('navbar-collapse collapsing');
                setTimeout(() => setShow('navbar-collapse collapse show'), 100);
            }
            else if (show === 'navbar-collapse collapse show') {
                setShow('navbar-collapse collapsing');
                setTimeout(() => setShow('navbar-collapse collapse'), 100);
            }
        }
        trackPageView();
    }

    return (
        <Fragment>
            <div className='navbar-component'>
                <nav className="navbar navbar-expand-lg fixed-top">
                    <div className="container">
                        <Scroll.Link className="navbar-brand" to="home" href="/" onClick={() => { onBurgerMenuClick(true); }}>
                            <img src={Logo} alt="solar-hero" width={"100px"} height={"100px"} className="d-inline-block align-text-top" />
                        </Scroll.Link>
                        <button className="navbar-toggler" onClick={() => onBurgerMenuClick()} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            {/*<span className="navbar-toggler-icon"></span>*/}
                            <div id="nav-icon" className={`menu-icon ${show === 'navbar-collapse collapse show' ? 'open' : ''}`}><span></span><span></span><span></span><span></span></div>
                        </button>
                        <div className={`${show}`} id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <Scroll.Link className={`nav-link`} spy={true} isDynamic={true} hashSpy={true} offset={window?.innerWidth <= 991 ? -20 : 0} aria-current="page" to="about-us" href="/#about-us" onClick={() => { onBurgerMenuClick(); }}>About Us</Scroll.Link>
                                </li>
                                <li className="nav-item">
                                    <Scroll.Link className={`nav-link`} spy={true} isDynamic={true} hashSpy={true} offset={window?.innerWidth <= 991 ? -20 : 0} aria-current="page" to="our-products" href="/#our-products" onClick={() => { onBurgerMenuClick(); }}>Our Products</Scroll.Link >
                                </li>
                                <li className="nav-item">
                                    <Scroll.Link className={`nav-link`} spy={true} isDynamic={true} hashSpy={true} offset={window?.innerWidth <= 991 ? -20 : 0} aria-current="page" to="how-it-works" href="/#how-it-works" onClick={() => { onBurgerMenuClick(); }}>How it Works</Scroll.Link >
                                </li>
                                <li className="nav-item">
                                    <Scroll.Link className={`nav-link`} spy={true} isDynamic={true} hashSpy={true} offset={window?.innerWidth <= 991 ? -20 : 0} aria-current="page" to="faq" href="/#faq" onClick={() => { onBurgerMenuClick(); }}>FAQ</Scroll.Link >
                                </li>
                                <li className="nav-item">
                                    <Scroll.Link className={`nav-link`} spy={true} isDynamic={true} hashSpy={true} offset={window?.innerWidth <= 991 ? -20 : 0} aria-current="page" to="contact-us" href="/#contact-us" onClick={() => { onBurgerMenuClick(); }}>Contact Us</Scroll.Link >
                                </li>
                                {<li className="nav-item">
                                    <Scroll.Link className="btn btn-primary" spy={true} isDynamic={true} hashSpy={true} offset={window?.innerWidth <= 991 ? -20 : 0} aria-current="page" to="get-a-quote" href="/#get-a-quote" onClick={() => { onBurgerMenuClick(); }}>Get a Quote</Scroll.Link >
                                </li>}
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </Fragment>
    );
}

export default Navbar;