import React from 'react';
import { Html } from '@react-email/html';

export const Email = ({ url, nameAndSurname, emailAddress, mobileNumber, reasonForEnquiry, yourMessage }) => {

  return (
    <Html lang="en">
      <body>
        <h1>{reasonForEnquiry}</h1>
        <p>Name and surname: <strong>{nameAndSurname}</strong><br/>
        Email address: <strong>{emailAddress}</strong><br/>
        Mobile number: <strong>{mobileNumber}</strong><br />
        Message: <strong>{yourMessage}</strong></p><br/>
        <img src={`${url}/logo512.png`} height={50} alt="logo" />
      </body>
    </Html>
  );
}

export const Quote = ({ url, nameAndSurname, emailAddress, mobileNumber, reasonForEnquiry, address, geysers, aircons, underFloorHeating, electricityBill, maxSaving, noMoreLoadshedding, bonus }) => {

  return (
    <Html lang="en">
      <body>
        <h1>{reasonForEnquiry}</h1>
        <p>Name and surname: <strong>{nameAndSurname}</strong><br />
          Mobile number: <strong>{mobileNumber}</strong><br />
          Email address: <strong>{emailAddress}</strong><br />
          Home address: <strong>{address || "N/A"}</strong><br />
          Number of geysers: <strong>{geysers || 0}</strong><br />
          Number of air conditions: <strong>{aircons || 0}</strong><br />
          Number of underfloor heating: <strong>{underFloorHeating || 0}</strong><br />
          Monthly electricity bill: <strong>R {electricityBill?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</strong><br />
          Maximum saving: <strong>{maxSaving ? "Yes" : "No"}</strong><br />
          No more loadshedding: <strong>{noMoreLoadshedding ? "Yes" : "No"}</strong><br />
          Saving is bonus: <strong>{bonus ? "Yes" : "No"}</strong></p><br/>
        <img src={`${url}/logo512.png`} height={50} alt="logo" />
      </body>
    </Html>
  );
}