import React, { Fragment } from 'react';
import Scroll from 'react-scroll';
import './Footer.scss';
import Logo from '../../logo.svg'


const Footer = () => {
    return (
        <Fragment>
            <div className='footer-component'>
                <div className="container">
                    <div className='row'>
                        <div className='col-12 col-sm-4'>
                            <img src={Logo} className='footer-logo' width={"100px"} height={"100px"} alt="logo" />
                        </div>
                        <div className='col-12 col-sm-4 footer-links'>
                            <ul>
                                <li>
                                    <Scroll.Link className={`nav-link`} spy={true} isDynamic={true} hashSpy={true}  offset={window?.innerWidth <= 991 ? -20 : 0} aria-current="page" to="about-us" href="/#about-us">About Us</Scroll.Link>
                                </li>
                                <li>
                                    <Scroll.Link className={`nav-link`} spy={true} isDynamic={true} hashSpy={true}  offset={window?.innerWidth <= 991 ? -20 : 0} aria-current="page" to="our-products" href="/#our-products">Our Products</Scroll.Link >
                                </li>
                                <li>
                                    <Scroll.Link className={`nav-link`} spy={true} isDynamic={true} hashSpy={true}  offset={window?.innerWidth <= 991 ? -20 : 0} aria-current="page" to="how-it-works" href="/#how-it-works">How it Works</Scroll.Link >
                                </li>
                                <li>
                                    <Scroll.Link className={`nav-link`} spy={true} isDynamic={true} hashSpy={true}  offset={window?.innerWidth <= 991 ? -20 : 0} aria-current="page" to="faq" href="/#faq">FAQ</Scroll.Link >
                                </li>
                                <li>
                                    <Scroll.Link className={`nav-link`} spy={true} isDynamic={true} hashSpy={true}  offset={window?.innerWidth <= 991 ? -20 : 0} aria-current="page" to="contact-us" href="/#contact-us">Contact Us</Scroll.Link >
                                </li>
                            </ul>
                        </div>
                        <div className='col-12 col-sm-4 footer-contact'>
                            <ul>
                                <li>
                                    Contact
                                </li>
                                <li>
                                    info@solarhero.co.za
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className='row mt-5'>
                        <div className='col-12'>
                            <div className="footer-text" aria-current="page">Solar Hero, all rights reserved. © Copy Right 2023.</div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Footer;