import React, { useState } from 'react';
import './QuoteForm.scss';

import { render } from '@react-email/render';
import { Quote } from '../sendgrid/Email';

import SendgridService from '../../services/SendgridService';

const GetAQuote = ({ children }) => {
    const [success, setSuccess] = useState(false);

    const [nameAndSurname, setNameAndSurname] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [address, setAddress] = useState('');
    const [geysers, setGeysers] = useState(0);
    const [aircons, setAircons] = useState(0);
    const [underFloorHeating, setUnderFloorHeating] = useState(0);
    const [electricityBill, setElectricityBill] = useState(0);
    const [maxSaving, setMaxSaving] = useState(false);
    const [noMoreLoadshedding, setNoMoreLoadshedding] = useState(false);
    const [bonus, setBonus] = useState(true);

    const [nameAndSurnameValid, setNameAndSurnameValid] = useState(null);
    const [emailAddressValid, setEmailAddressValid] = useState(null);
    const [mobileNumberValid, setMobileNumberValid] = useState(null);

    const validateForm = () => {
        if (nameAndSurname.trim() !== '') {
            setNameAndSurnameValid(true);
        } else {
            setNameAndSurnameValid(false);
        }

        if (/^\S+@\S+\.\S+$/.test(emailAddress.trim())) {
            setEmailAddressValid(true);
        } else {
            setEmailAddressValid(false);
        }

        if (/^(\+27|0)([6-8][0-9]{8})$/.test(mobileNumber.trim())) {
            setMobileNumberValid(true);
        } else {
            setMobileNumberValid(false);
        }

        return nameAndSurnameValid &&
            emailAddressValid &&
            mobileNumberValid;
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }

        const emailHtmlTemplate = render(<Quote url={window.location.host} nameAndSurname={nameAndSurname} emailAddress={emailAddress} mobileNumber={mobileNumber} reasonForEnquiry={"Quote"} address={address} geysers={geysers} aircons={aircons} underFloorHeating={underFloorHeating} electricityBill={electricityBill} maxSaving={maxSaving} noMoreLoadshedding={noMoreLoadshedding} bonus={bonus} />);
        const options = {
            from: `noreply@solarhero.co.za`,
            to: `${process.env.REACT_APP_EMAIL_ADDRESS}`,
            subject: "Quote",
            text: emailAddress + ": Quote",
            html: emailHtmlTemplate,
        };

        const services = new SendgridService();
        await services.sendEmail(options);
        setSuccess(true);
        setTimeout(() => {
            setSuccess(false);
            setNameAndSurname('');
            setEmailAddress('');
            setMobileNumber('');
            setNameAndSurnameValid(null);
            setEmailAddressValid(null);
            setMobileNumberValid(null);
            setAddress('');
            setGeysers(0);
            setAircons(0);
            setUnderFloorHeating(0);
            setElectricityBill(0);
            setMaxSaving(false);
            setNoMoreLoadshedding(false);
            setBonus(true);
        }, 5000);
        setSuccess(true);
    }

    return (
        <div className={`quote-form`}>
            <div>
                {!success ? <div className='row justify-content-center mt-5'>
                    <div className='col-12'>
                        <form className='needs-validation' onSubmit={(e) => onSubmit(e)} noValidate>
                            <div className="mb-3 form-floating">
                                <input type="text" placeholder="Name & Surname" className={`form-control ${nameAndSurnameValid === null ? '' : nameAndSurnameValid ? 'is-valid' : 'is-invalid'}`} id="NameAndSurnameQuoteForm" value={nameAndSurname} onChange={(e) => { setNameAndSurnameValid(e.target.value.trim() !== ''); setNameAndSurname(e.target.value) }} />
                                <label htmlFor="NameAndSurnameQuoteForm" className="form-label">Name & Surname</label>
                                <div className="invalid-feedback">
                                    Please provide your name and surname.
                                </div>
                            </div>

                            <div className="mb-3 form-floating">
                                <input type="text" placeholder="+27821234567" className={`form-control ${mobileNumberValid === null ? '' : mobileNumberValid ? 'is-valid' : 'is-invalid'}`} id="MobileNumberQuoteForm" value={mobileNumber} onChange={(e) => { setMobileNumberValid(/^(\+27|0)([6-8][0-9]{8})$/.test(e.target.value.trim())); setMobileNumber(e.target.value) }} />
                                <label htmlFor="MobileNumberQuoteForm" className="form-label">Mobile Number</label>
                                <div className="invalid-feedback">
                                    {`Please provide ${mobileNumber === '' ? 'your' : 'a valid'} mobile number.`}
                                </div>
                            </div>
                            <div className="mb-3 form-floating">
                                <input type="text" placeholder="name@example.com" className={`form-control ${emailAddressValid === null ? '' : emailAddressValid ? 'is-valid' : 'is-invalid'}`} id="EmailAddressQuoteForm" value={emailAddress} onChange={(e) => { setEmailAddressValid(/^\S+@\S+\.\S+$/.test(e.target.value.trim())); setEmailAddress(e.target.value) }} />
                                <label htmlFor="EmailAddressQuoteForm" className="form-label">Email Address</label>
                                <div className="invalid-feedback">
                                    {`Please provide ${emailAddress === '' ? 'your' : 'a valid'} email address.`}.
                                </div>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="RangeQuoteForm" className="form-label">
                                    How much is your typical monthly electricity bill?<br/>
                                    <p className='mb-0 mt-0'>R {electricityBill?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</p>
                                </label>
                                <input type="range" value={electricityBill} step={100} min={100} max={10000} onChange={(e) => { console.log(e.target); setElectricityBill(e.target.value) }} className="form-range custom-range" id="RangeQuoteForm" />
                            </div>

                            <div className="mb-3">
                                <label htmlFor='HowManyQuoteForm'>
                                    How many geysers, underfloor heating and air conditioners do you have in your home?
                                </label>
                                <div className='d-block d-sm-flex'>
                                    <div className="number-control">
                                        <label htmlFor='GeysersQuoteForm' className='mb-1 mt-3 text-center'>Geysers</label>
                                        <div className="input-group">
                                            <span className="input-group-btn">
                                                <button type="button" className="btn btn-secondary" disabled={geysers <= 0} data-type="minus" data-field="quant[1]" onClick={() => { setGeysers(geysers - 1) }} >
                                                    -
                                                </button>
                                            </span>
                                            <input type="text" id="GeysersQuoteForm" name="quant[1]" className="form-control" value={geysers} min="0" max="5" onChange={(e) => { setGeysers(e.target.value) }} readOnly />
                                            <span className="input-group-btn">
                                                <button type="button" className="btn btn-secondary" disabled={geysers >= 5} data-type="plus" data-field="quant[1]" onClick={() => { setGeysers(geysers + 1) }} >
                                                    +
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="number-control">
                                        <label htmlFor="AirQuoteForm" className='mb-1 mt-3 text-center'>Air Conditioners</label>
                                        <div className="input-group">
                                            <span className="input-group-btn">
                                                <button type="button" className="btn btn btn-secondary" disabled={aircons <= 0} data-type="minus" data-field="quant[2]" onClick={() => { setAircons(aircons - 1) }} >
                                                    -
                                                </button>
                                            </span>
                                            <input type="text" id="AirQuoteForm" name="quant[2]" className="form-control" value={aircons} min="0" max="5" onChange={(e) => { setAircons(e.target.value) }} readOnly />
                                            <span className="input-group-btn">
                                                <button type="button" className="btn btn btn-secondary" disabled={aircons >= 5} data-type="plus" data-field="quant[2]" onClick={() => { setAircons(aircons + 1) }} >
                                                    +
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="number-control">
                                        <label htmlFor="UnderQuoteForm" className='mb-1 mt-3 text-center'>Underfloor Heating</label>
                                        <div className="input-group">
                                            <span className="input-group-btn">
                                                <button type="button" className="btn btn btn-secondary" disabled={underFloorHeating <= 0} data-type="minus" data-field="quant[3]" onClick={() => { setUnderFloorHeating(underFloorHeating - 1) }} >
                                                    -
                                                </button>
                                            </span>
                                            <input type="text" id="UnderQuoteForm" name="quant[3]" className="form-control" value={underFloorHeating} min="0" max="5" onChange={(e) => { setUnderFloorHeating(e.target.value) }} readOnly />
                                            <span className="input-group-btn">
                                                <button type="button" className="btn btn btn-secondary" disabled={underFloorHeating >= 5} data-type="plus" data-field="quant[3]" onClick={() => { setUnderFloorHeating(underFloorHeating + 1) }} >
                                                    +
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mb-3 form-floating">
                                <input type="text" placeholder="name@example.com" className={`form-control`} id="AddressQuoteForm" value={address} onChange={(e) => { setAddress(e.target.value) }} />
                                <label htmlFor="AddressQuoteForm" className="form-label">What is your home address?
                                    <p >
                                        We would love to assess the perfect placement of solar panels on your roof.
                                    </p>
                                </label>
                            </div>

                            <div className="mb-3">
                                <p>
                                    To better understand your solar goals, please let us know what you hope to achieve with the solar energy system?
                                </p>
                                <div className="form-check ">
                                    <input type="checkbox" value={maxSaving} onChange={(e) => { setMaxSaving(e.target.checked) }} className={`form-check-input`} id="MaxSavingQuoteForm" />
                                    <label htmlFor="MaxSavingQuoteForm" className="form-check-label">Maximum savings</label>
                                </div>
                                <div className="form-check ">
                                    <input type="checkbox" value={noMoreLoadshedding} onChange={(e) => { setNoMoreLoadshedding(e.target.checked) }} className={`form-check-input`} id="NoMoreQuoteForm" />
                                    <label htmlFor="NoMoreQuoteForm" className="form-check-label">No more loadshedding</label>
                                </div>
                                <div className="form-check ">
                                    <input type="checkbox" value={bonus} onChange={(e) => { setBonus(e.target.checked) }} className={`form-check-input`} id="BonusQuoteForm" />
                                    <label htmlFor="BonusQuoteForm" className="form-check-label">Savings is a bonus</label>
                                </div>
                            </div>

                            <button type="submit" className="btn btn-secondary float-right">Submit</button>
                        </form>
                    </div>
                </div> :
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center mt-3'>
                            <p className='mb-0'>Thank you for requesting a quote.</p>
                        </div>
                    </div>}
                {children}
            </div>
        </div>
    );
}

export default GetAQuote;