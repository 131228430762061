// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quote-form .container {
  padding-left: 0px;
  padding-right: 0px;
}
.quote-form .form-label {
  color: #000000 !important;
  font-size: 14px;
  width: 100%;
}
.quote-form .form-label p {
  font-size: 10px;
  margin-top: -5px;
}
.quote-form .form-control {
  border: 0px solid #8E7936;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
  font-family: "Calibri", sans-serif;
  font-size: 14px;
}
.quote-form .form-control.form-textarea {
  min-height: 200px;
}
.quote-form .form-select {
  border: 2px solid #8E7936;
}
.quote-form .number-control {
  width: 150px;
  margin-right: 10px;
}
.quote-form .number-control .form-control {
  text-align: center;
}
.quote-form .form-range::-webkit-slider-thumb {
  background: #000000 !important;
}
.quote-form .form-range::-moz-range-thumb {
  background: #000000 !important;
}
.quote-form .form-range::-ms-thumb {
  background: #000000 !important;
}
.quote-form .form-check-input {
  border-color: #000000;
}
.quote-form .form-check-input:checked {
  background-color: #000000;
  border-color: #000000;
}`, "",{"version":3,"sources":["webpack://./src/components/quote-form/QuoteForm.scss"],"names":[],"mappings":"AAEI;EACI,iBAAA;EACA,kBAAA;AADR;AAII;EACI,yBAAA;EACA,eAAA;EACA,WAAA;AAFR;AAIQ;EACI,eAAA;EACA,gBAAA;AAFZ;AAMI;EACI,yBAAA;EACA,uDAAA;EACA,kCAAA;EACA,eAAA;AAJR;AAOQ;EACI,iBAAA;AALZ;AASI;EACI,yBAAA;AAPR;AAUI;EACI,YAAA;EACA,kBAAA;AARR;AAUQ;EACI,kBAAA;AARZ;AAYI;EACI,8BAAA;AAVR;AAaI;EACI,8BAAA;AAXR;AAcI;EACI,8BAAA;AAZR;AAeI;EACI,qBAAA;AAbR;AAgBI;EACI,yBAAA;EACA,qBAAA;AAdR","sourcesContent":[".quote-form {\n\n    .container {\n        padding-left: 0px;\n        padding-right: 0px;\n    }\n\n    .form-label {\n        color: #000000 !important;\n        font-size: 14px;\n        width: 100%;\n\n        p {\n            font-size: 10px;\n            margin-top: -5px;\n        }\n    }\n\n    .form-control {\n        border: 0px solid #8E7936;\n        box-shadow: 0px 4px 4px 0px #00000040;\n        font-family: \"Calibri\", sans-serif;\n        font-size: 14px;\n\n\n        &.form-textarea {\n            min-height: 200px;\n        }\n    }\n\n    .form-select {\n        border: 2px solid #8E7936;\n    }\n\n    .number-control {\n        width: 150px;\n        margin-right: 10px;\n\n        .form-control {\n            text-align: center;\n        }\n    }\n\n    .form-range::-webkit-slider-thumb {\n        background: #000000 !important;\n    }\n\n    .form-range::-moz-range-thumb {\n        background: #000000 !important;\n    }\n\n    .form-range::-ms-thumb {\n        background: #000000 !important;\n    }\n\n    .form-check-input {\n        border-color: #000000;\n    }\n\n    .form-check-input:checked {\n        background-color: #000000;\n        border-color: #000000;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
