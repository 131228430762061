import React, { Fragment, useState } from 'react';
import Scroll from 'react-scroll';
import "./HomePage.scss"

import {Helmet} from "react-helmet";

import ImageSlider from '../components/image-slider/ImageSlider';
import GetAQuoteForm from '../components/quote-form/QuoteForm';
import ContactUsForm from '../components/contact-us-form/ContactUsForm';
import NumberSpinner from '../components/number-spinner/NumberSpinner';

import Logo from '../logo.svg';
import Icon from '../images/icon.svg';
import Banner from '../images/banner.webp';
import AboutUs01 from '../images/about_us_01.webp';
import Underline from '../images/underline.webp';
import UnderlineBlack from '../images/underline_black.webp';

import Slider01 from '../images/slider_01.webp';
import Slider02 from '../images/slider_02.webp';
import Slider03 from '../images/slider_03.webp';
import Slider04 from '../images/slider_04.webp';
import Slider05 from '../images/slider_05.webp';

import Step01 from '../images/step_01.svg';
import Step02 from '../images/step_02.svg';
import Step03 from '../images/step_03.svg';
import Step04 from '../images/step_04.svg';

import GetAQuote01 from '../images/quote_desktop.webp';
import GetAQuote02 from '../images/quote_mobile.webp';


const HomePage = () => {

    const [openStep, setOpenStep] = useState(0);
    const [openFaq, setOpenFaq] = useState(0);

    return (
        <Fragment>
            <Helmet>
                <title>Solar Hero | Your partner in solar solutions</title>
                <meta name="description" content="Solar Hero is a leading solar energy company in South Africa. We offer a range of solar solutions, including solar panels, inverters, and battery storage solutions. Our experts provide personalized consultations and installations, ensuring maximum efficiency and savings. We also offer proactive monitoring and support, guaranteeing a seamless solar experience. Contact us today to learn more about our solar solutions." />
                <meta name="keywords" content="solar panels, solar inverters, solar batteries, solar energy, solar solutions, solar company, solar energy company, solar energy company in South Africa, solar energy company in Johannesburg, solar energy company in Cape Town, solar energy company in Durban, solar energy company in Pretoria, solar energy company in Port Elizabeth, solar energy company in Bloemfontein, solar energy company in East London, solar energy company in Polokwane, solar energy company in Nelspruit, solar energy company in Kimberley, solar energy company in Pietermaritzburg, solar energy company in Rustenburg, solar energy company in George, solar energy company in Klerksdorp, solar energy company in Witbank, solar energy company in Richards Bay, solar energy company in Middelburg, solar energy company in Welkom, solar energy company in Vryheid, solar energy company in Newcastle, solar energy company in Krugersdorp, solar energy company in Grahamstown" />
            </Helmet>

            <div id="home">
                <div className='home-page container'>
                    <img className="placeholder-img" src={Banner} alt="placeholder" width={"3315px"} height={"1783px"}/>
                    <div className='banner-content'>
                        <img className='logo' src={Logo} alt='logo' width={"100px"} height={"100px"} />
                        <div className='title'>Your partner in solar solutions</div>
                        <div className='banner-btns'>
                            <Scroll.Link className='btn btn-primary' spy={true} isDynamic={true} hashSpy={true}  offset={window?.innerWidth <= 991 ? -20 : 0} to='get-a-quote' href="/#get-a-quote">Get a Quote</Scroll.Link>
                            <Scroll.Link className='btn btn-secondary' spy={true} isDynamic={true} hashSpy={true}  offset={window?.innerWidth <= 991 ? -20 : 0} to='contact-us' href="/#contact-us">Contact Us</Scroll.Link>
                        </div>
                    </div>
                </div>
            </div>

            <div id="about-us">
                <div className='about-us-page-01 container'>
                    <div className='row m-0'>
                        <div className='col-12 col-sm-6 block-01'>
                            <div className="content">
                                <img className='about-us-01' src={AboutUs01} alt='about-us-01' width={"1028px"} height={"768px"} />
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 block-02' >
                            <div className="content">
                                <div className='title'>
                                    Solar Panel <span>Expert</span> Advice & Assistance
                                </div>
                                <p className='text'>
                                We're more than just installers; we're here to empower your solar journey. Our mission is simple: deliver impactful and renewable energy. We're focused on quality and satisfaction, offering a partnership in solar. Our experts understand your unique goals, whether it's saving money or making loadshedding disappear. Let us guide you at every step.
                                </p>
                                <Scroll.Link className='btn btn-primary' spy={true} isDynamic={true} hashSpy={true}  offset={window?.innerWidth <= 991 ? -20 : 0} to='contact-us' href="/#contact-us">Contact Us</Scroll.Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="our-products">
                <div className='our-products-01 container'>
                    <div className='row m-0'>
                        <div className='col-12 col-sm-6 block-01'>
                            <div className="content">
                                <p className='top-subtitle'>
                                    <img className='pr-2' height="16px" width={"20px"} src={Icon} alt="icon" /> Regulations-Compliant Installations
                                </p>
                                <div className='title'>
                                    We Only Sell Premium Products<br /><span>No Shortcuts!<img src={Underline} alt="underline" width={"100px"} height={"100px"} /></span>
                                </div>
                                <p className='text'>
                                    Explore our cutting-edge solar products, curated to meet your energy needs perfectly. At Solar Hero, we customize every solution for maximum efficiency and savings, surpassing your expectations.
                                </p>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 block-02' >
                            <div className="content">
                                <ImageSlider images={[
                                    { url: Slider01, title: "Solar Panels", text: "Embrace the sun's energy with our premium selection of tier-1 solar panels from renowned brands like Canadian Solar, Longi, and JA Solar. These high-performance panels are designed to harness sunlight effectively, delivering a consistent and sustainable energy supply to your home or business." },
                                    { url: Slider02, title: "Inverters", text: "Discover the world of seamless energy conversion through our state-of-the-art inverters. We are proud to collaborate with renowned brands like Victron and Sunsynk, ensuring unwavering reliability and peak system performance. Let efficiency and quality define your solar setup." },
                                    { url: Slider03, title: "Battery Storage Solutions", text: "Elevate your solar investment with our exceptional battery storage solutions. Prepare for any scenario as you maximize your solar power usage. Select from our range of top-rated battery options, including BSL, Pylontech, and Freedom Won batteries, known for their durability and outstanding storage capabilities." },
                                    { url: Slider04, title: "Expert Installations", text: "Leave your solar installation in the hands of our experienced professionals. With an unwavering commitment to quality and adherence to regulations, we ensure flawless installations that stand the test of time." },
                                    { url: Slider05, title: "Comprehensive Monitoring", text: "Rest easy knowing your solar system's performance is being closely monitored by our team. We provide proactive insights and assistance, guaranteeing that your solar investment is always optimized for the best results." },
                                ]} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='our-products-02 container'>
                    <div className='row m-0 main-block'>
                        <div className='col-12'>
                            <div className="main-title">
                                How We Have Helped <span>South African<img src={Underline} width={"100px"} height={"100px"} alt="underline" /></span> Homes
                            </div>
                        </div>
                        <div className='col-12 col-sm-4 block-01'>
                            <div className="content">
                                <div className='title'>
                                    <img className='pr-5' height="32px" width={"20px"} src={Icon} alt="icon" />
                                    <NumberSpinner value={1} max={173540} inc={1928} prefix={""} suffix={" Kwp"} />
                                </div>
                                <p className='text'>
                                    Kwp's panels we have installed
                                </p>
                            </div>
                        </div>
                        <div className='col-12 col-sm-4 block-02' >
                            <div className="content">
                                <div className='title'>
                                    <img className='pr-2' height="32px" width={"20px"} src={Icon} alt="icon" />
                                    <NumberSpinner value={1} max={552238} inc={6136} prefix={"R "} suffix={""} />
                                </div>
                                <p className='text'>
                                    Cost that we have already saved for our clients in 2023
                                </p>
                            </div>
                        </div>
                        <div className='col-12 col-sm-4 block-03' >
                            <div className="content">
                                <div className='title'>
                                    <img className='pr-2' height="32px" width={"20px"} src={Icon} alt="icon" />
                                    <NumberSpinner value={1} max={901} inc={10}  prefix={""} suffix={" Hours"} />
                                </div>
                                <p className='text'>
                                    Of load shedding in 2023 so far
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="how-it-works">
                <div className='how-it-works-01 container'>
                    <div className='row m-0 about-us-page-04'>
                        <div className='col-12 col-sm-6 block-01 mx-auto'>
                            <div className="content text-center">
                                <p className='top-subtitle'>
                                    <img className='pr-2' height="16px" width={"20px"} src={Icon} alt="icon" /> From Sunlight to Savings
                                </p>
                                <div className='title'>
                                    The Pathway to Solar <span>Success<img src={Underline} width={"100px"} height={"100px"} alt="underline" /></span>
                                </div>
                                <p className='text'>
                                    Solar Hero: Your seamless solar transition. Our efficient process caters to your unique needs. Follow our pathway to turn sunlight into substantial savings for you.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='how-it-works-02 container'>
                    <div className='row m-0'>
                        <div className='col-12 col-sm-3 block-01'>
                            <div className="content line text-center" onClick={() => {openStep === 1 ? setOpenStep(0) : setOpenStep(1)}}>
                                <img className='pr-2 icon' src={Step01} width={"10px"} height={"10px"} alt="icon" />
                                <div className='title'>
                                    {window?.innerWidth <= 991 && <span className={openStep === 1 ? 'close' : 'open'}></span>}Step 1:  Consultation And {window?.innerWidth > 991 && <br />}<span>Quotation</span>
                                </div>
                                {(openStep === 1 || window?.innerWidth > 991) && <p className='text'>
                                    Discover solar independence with our personalized consultation and supercharged quotation process. Our experts design the perfect solar solution for your needs, aspirations, and budget, ensuring confidence in your decision. Take the first stride now!
                                </p>}
                            </div>
                        </div>
                        <div className='col-12 col-sm-3 block-01'>
                            <div className="content line text-center" onClick={() => {openStep === 2 ? setOpenStep(0) : setOpenStep(2)}}>
                                <img className='pr-2 icon' height="50px" width={"10px"} src={Step02} alt="icon" />
                                <div className='title'>
                                    {window?.innerWidth <= 991 && <span className={openStep === 2 ? 'close' : 'open'}></span>}Step 2: Solar Superhero {window?.innerWidth > 991 && <br />}<span>Installation</span>
                                </div>
                                {(openStep === 2 || window?.innerWidth > 991) && <p className='text'>
                                    Watch as our Solar Superheroes spring into action! Our skilled team will flawlessly install top-tier solar panels, inverters, and battery solutions, adhering to regulations and delivering a system built for long-lasting performance.
                                </p>}
                            </div>
                        </div>
                        <div className='col-12 col-sm-3 block-01'>
                            <div className="content line text-center" onClick={() => {openStep === 3 ? setOpenStep(0) : setOpenStep(3)}}>
                                <img className='pr-2 icon' height="50px" width={"10px"} src={Step03} alt="icon" />
                                <div className='title'>
                                    {window?.innerWidth <= 991 && <span className={openStep === 3 ? 'close' : 'open'}></span>}Step 3: Empowering {window?.innerWidth > 991 && <br />}<span>Monitoring</span>
                                </div>
                                {(openStep === 3 || window?.innerWidth > 991) && <p className='text'>
                                    Your solar journey doesn't end with installation. We continuously monitor your system's performance, offering proactive insights and suggestions to maximize savings and efficiency. Our mission is your complete satisfaction.
                                </p>}
                            </div>
                        </div>
                        <div className='col-12 col-sm-3 block-01'>
                            <div className="content text-center" onClick={() => {openStep === 4 ? setOpenStep(0) : setOpenStep(4)}}>
                                <img className='pr-2 icon' height="50px" width={"10px"} src={Step04} alt="icon" />
                                <div className='title'>
                                    {window?.innerWidth <= 991 && <span className={openStep === 4 ? 'close' : 'open'}></span>}Step 4: Sunlit {window?.innerWidth > 991 && <br />}<span>Savings</span>
                                </div>
                                {(openStep === 4 || window?.innerWidth > 991) && <p className='text'>
                                    Bask in the glow of solar success! As your solar panels harness the sun's energy, revel in substantial savings on your energy bills and a reduced carbon footprint. With Solar Hero, you're not just saving money; you're making a positive impact on the planet.
                                </p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="get-a-quote">
                <div className='get-a-quote-page-01 container'>
                    <div className='row m-0'>
                        <div className='col-12 col-sm-6 block-01 order-2 order-sm-1'>
                            <div className="content">
                                <div className='title'>
                                    Get A <span>Free Quote!<img src={UnderlineBlack} width={"100px"} height={"100px"} alt="underline" /></span>
                                </div>
                                {<GetAQuoteForm />}
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 block-02 order-1 order-sm-2'>
                            <div className="content">
                                <img className='get-a-quote-01'  width={"705px"} height={"922px"} src={window?.innerWidth > 991 ? GetAQuote01 : GetAQuote02} alt='get-a-quote-01' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='get-a-quote-page-02 container'>
                    <div className='row m-0'>
                        <div className='col-12 block-01'>
                            <div className="content">
                                <div className='title'>
                                    <img className='' height="32px" width={"20px"} src={Icon} alt="icon" /> What Our Clients Are <span>Saying<img src={Underline} width={"100px"} height={"100px"} alt="underline" /></span>
                                </div>
                                <p className='text'>
                                    Discover a range of cutting-edge solar products meticulously curated to meet your energy needs and exceed your expectations. At Solar Hero, we believe that every solar solution should be tailor-made for you, ensuring maximum efficiency and savings.
                                    <br /><span>
                                        Matthew Proudfoot
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="faq">
                <div className='faq-01 container'>
                    <div className='row m-0 about-us-page-04'>
                        <div className='col-12 col-sm-8 block-01 mx-auto'>
                            <div className="content text-center">
                                <p className='top-subtitle'>
                                    <img className='pr-2' height="16px" width={"20px"} src={Icon} alt="icon" /> Unleash Your Solar Curiosity
                                </p>
                                <div className='title'>
                                    Empowering Answers to Common <span>Questions<img src={Underline} width={"100px"} height={"100px"} alt="underline" /></span>
                                </div>
                                <p className='text'>
                                    At Solar Hero, we empower you with knowledge for confident solar decisions. Our "FAQ" section demystifies solar energy, answering your most common questions. Be informed, make the best choices for your energy needs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='faq-02 container'>
                    <div className='row m-0 about-us-page-04'>
                        <div className='col-12 col-sm-8 block-01 mx-auto'>
                            <div className="content text-center">
                                <ul>
                                    <li onClick={() => {openFaq === 1 ? setOpenFaq(0) : setOpenFaq(1)}}>
                                        <div className='title'>
                                            {<span className={openFaq === 1 ? 'close' : 'open'}></span>}What sets Solar Hero apart from other solar installers?
                                        </div>
                                        {openFaq === 1 && <p className='text'>
                                            Our dedication to being more than just solar installers. We go the extra mile by actively monitoring your system's performance, offering valuable suggestions, and ensuring you get the optimal benefits from your solar setup. Plus, we never compromise on quality, using only top-notch products and following strict regulations.
                                        </p>}
                                    </li>

                                    <li onClick={() => {openFaq === 2 ? setOpenFaq(0) : setOpenFaq(2)}}>
                                        <div className='title'>
                                            {<span className={openFaq === 2 ? 'close' : 'open'}></span>}How do you determine the right solar solution for my needs?
                                        </div>
                                        {openFaq === 2 && <p className='text'>
                                            Your needs are unique, and we treat them that way! Our solar experts conduct personalized consultations, assessing your energy requirements, budget, and goals to design a tailor-made solar solution just for you.
                                        </p>}
                                    </li>

                                    <li onClick={() => {openFaq === 3 ? setOpenFaq(0) : setOpenFaq(3)}}>
                                        <div className='title'>
                                            {<span className={openFaq === 3 ? 'close' : 'open'}></span>}What brands of products do you install?
                                        </div>
                                        {openFaq === 3 && <p className='text'>
                                            We take pride in partnering with the best in the industry. For solar panels, we offer premium options from Canadian Solar, Longi, and JA Solar. Our inverters come from renowned names like Victron and Sunsynk, ensuring efficiency and reliability. And when it comes to batteries, we offer BSL, Pylontech, and Freedom Won for lasting energy storage.
                                        </p>}
                                    </li>

                                    <li onClick={() => {openFaq === 4 ? setOpenFaq(0) : setOpenFaq(4)}}>
                                        <div className='title'>
                                            {<span className={openFaq === 4 ? 'close' : 'open'}></span>}Do I need to wait for a site visit to get a quote?
                                        </div>
                                        {openFaq === 4 && <p className='text'>
                                            Not at all! We understand that time is of the essence. Our preliminary quoting process is lightning-fast. With just a few key questions about your monthly bill, appliances, and address, we can provide you with an initial quote to kickstart your solar journey.
                                        </p>}
                                    </li>

                                    <li onClick={() => {openFaq === 5 ? setOpenFaq(0) : setOpenFaq(5)}}>
                                        <div className='title'>
                                            {<span className={openFaq === 5 ? 'close' : 'open'}></span>}How will solar energy impact my savings?
                                        </div>
                                        {openFaq === 5 && <p className='text'>
                                            Solar energy is your ticket to substantial savings! By harnessing the sun's power, you'll see a significant reduction in your monthly energy bills, allowing you to save more while contributing to a greener environment.
                                        </p>}
                                    </li>

                                    <li onClick={() => {openFaq === 6 ? setOpenFaq(0) : setOpenFaq(6)}}>
                                        <div className='title'>
                                            {<span className={openFaq === 6 ? 'close' : 'open'}></span>}What if I encounter issues with my solar system?
                                        </div>
                                        {openFaq === 6 && <p className='text'>
                                            Fear not, Solar Hero is here to save the day! Our dedicated support team is ready to assist you with any issues you might face. We ensure a smooth and hassle-free solar experience for all our valued clients.
                                        </p>}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="contact-us">
                <div className='contact-us-01 container'>
                    <div className='row m-0'>
                    <div className='col-12 col-sm-8 block-01'>
                            <div className="content">
                                <div className='title'>
                                Have Any <span>Questions?<img src={Underline} width={"100px"} height={"100px"} alt="underline" /></span>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-sm-8 block-02'>
                            <div className="content">
                                {<ContactUsForm />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default HomePage;