import React, { useState } from 'react';
import './ContactUsForm.scss';

import { render } from '@react-email/render';
import { Email } from '../sendgrid/Email';

import SendgridService from '../../services/SendgridService';

const ContactUs = ({ children }) => {
    const [success, setSuccess] = useState(false);

    const [nameAndSurname, setNameAndSurname] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [yourMessage, setYourMessage] = useState('');

    const [nameAndSurnameValid, setNameAndSurnameValid] = useState(null);
    const [emailAddressValid, setEmailAddressValid] = useState(null);
    const [mobileNumberValid, setMobileNumberValid] = useState(null);
    const [yourMessageValid, setYourMessageValid] = useState(null);

    const validateForm = () => {
        if (nameAndSurname.trim() !== '') {
            setNameAndSurnameValid(true);
        } else {
            setNameAndSurnameValid(false);
        }

        if (/^\S+@\S+\.\S+$/.test(emailAddress.trim())) {
            setEmailAddressValid(true);
        } else {
            setEmailAddressValid(false);
        }

        if (/^(\+27|0)([6-8][0-9]{8})$/.test(mobileNumber.trim())) {
            setMobileNumberValid(true);
        } else {
            setMobileNumberValid(false);
        }

        if (yourMessage.trim() !== '') {
            setYourMessageValid(true);
        } else {
            setYourMessageValid(false);
        }

        return nameAndSurnameValid &&
            emailAddressValid &&
            mobileNumberValid &&
            yourMessageValid;
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }

        const emailHtmlTemplate = render(<Email url={window.location.host} nameAndSurname={nameAndSurname} emailAddress={emailAddress} mobileNumber={mobileNumber} reasonForEnquiry={"Contact Us"} yourMessage={yourMessage} />);
        const options = {
            from: `noreply@solarhero.co.za`,
            to: `${process.env.REACT_APP_EMAIL_ADDRESS}`,
            subject: "Contact Us",
            text: emailAddress + ": " + yourMessage,
            html: emailHtmlTemplate,
        };

        const services = new SendgridService();
        await services.sendEmail(options);
        setSuccess(true);
        setTimeout(() => {
            setSuccess(false);
            setNameAndSurname('');
            setEmailAddress('');
            setMobileNumber('');
            setYourMessage('');
            setNameAndSurnameValid(null);
            setEmailAddressValid(null);
            setMobileNumberValid(null);
            setYourMessageValid(null);
        }, 5000);
    }

    return (
        <div className={`contact-us-form`}>
            <div className='container'>
                {!success ? <div className='row justify-content-center'>
                    <div className='col-12'>
                        <form className='needs-validation' onSubmit={(e) => onSubmit(e)} noValidate>
                            <div className="mb-3 form-floating">
                                <input type="text" placeholder="Name & Surname" className={`form-control ${nameAndSurnameValid === null ? '' : nameAndSurnameValid ? 'is-valid' : 'is-invalid'}`} id="NameAndSurname" value={nameAndSurname} onChange={(e) => { setNameAndSurnameValid(e.target.value.trim() !== ''); setNameAndSurname(e.target.value) }} />
                                <label htmlFor="NameAndSurname" className="form-label">Name & Surname</label>
                                <div className="invalid-feedback">
                                    Please provide your name and surname.
                                </div>
                            </div>
                            <div className="mb-3 form-floating">
                                <input type="text" placeholder="name@example.com" className={`form-control ${emailAddressValid === null ? '' : emailAddressValid ? 'is-valid' : 'is-invalid'}`} id="EmailAddress" value={emailAddress} onChange={(e) => { setEmailAddressValid(/^\S+@\S+\.\S+$/.test(e.target.value.trim())); setEmailAddress(e.target.value) }} />
                                <label htmlFor="EmailAddress" className="form-label">Email Address</label>
                                <div className="invalid-feedback">
                                    {`Please provide ${emailAddress === '' ? 'your' : 'a valid'} email address.`}.
                                </div>
                            </div>
                            <div className="mb-3 form-floating">
                                <input type="text" placeholder="+27821234567" className={`form-control ${mobileNumberValid === null ? '' : mobileNumberValid ? 'is-valid' : 'is-invalid'}`} id="MobileNumber" value={mobileNumber} onChange={(e) => { setMobileNumberValid(/^(\+27|0)([6-8][0-9]{8})$/.test(e.target.value.trim())); setMobileNumber(e.target.value) }} />
                                <label htmlFor="MobileNumber" className="form-label">Mobile Number</label>
                                <div className="invalid-feedback">
                                    {`Please provide ${mobileNumber === '' ? 'your' : 'a valid'} mobile number.`}
                                </div>
                            </div>
                            <div className="mb-3 form-floating">
                                <textarea placeholder="Your Message" className={`form-control form-textarea ${yourMessageValid === null ? '' : yourMessageValid ? 'is-valid' : 'is-invalid'}`} id="YourMessage" value={yourMessage} onChange={(e) => { setYourMessageValid(e.target.value.trim() !== ""); setYourMessage(e.target.value) }}></textarea>
                                <label htmlFor="YourMessage">Your Message</label>
                            </div>
                            <button type="submit" className="btn btn-secondary float-right">Send Us A Message!</button>
                        </form>

                    </div>
                </div> :
                    <div className='row justify-content-center'>
                       <div className='col-12 text-center mt-3'>
                            <p className='mb-0'>Thank you for contacting us.</p>
                        </div>
                    </div>}
                {children}
            </div>
        </div>
    );
}

export default ContactUs;