import React, { useState, useEffect } from 'react';
import './ImageSlider.scss';

const ImageSlider = ({ images }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const autoScrollInterval = 6000; // Auto-scroll interval in milliseconds

    useEffect(() => {
        const interval = setInterval(nextSlide, autoScrollInterval);

        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSlide]);

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    };

    /*const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + images.length) % images.length);
    };*/

    const goToSlide = (index) => {
        setCurrentSlide(index);
    };

    return (
        <div className="image-slider-container">
            <div className="image-slider" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                {images.map((image, index) => (
                    <div key={index} className="image-slide">
                        <img src={image.url} alt={`Slide ${index + 1}`} className="image-slide" />
                        <div className="text-overlay pt-1 pt-sm-5">
                            <div className='title'>
                                {image?.title}
                            </div>
                            <p className='text'>
                                {image?.text}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="slide-indicators">
                {images.map((_, index) => (
                    <div
                        key={index}
                        className={`slide-indicator ${index === currentSlide ? 'active-indicator' : ''}`}
                        onClick={() => goToSlide(index)}
                    />
                ))}
            </div>
        </div>
    );
};

export default ImageSlider;
