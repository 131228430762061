import React from 'react';
import { Routes, Route } from 'react-router-dom';

import HomePage from '../pages/HomePage';

const PublicRoutes = () => {
    return (
        <Routes>
            <Route exact={true} path="/" element={<HomePage />} />
            <Route exact={true} path="*"
                element={<HomePage />}
            />
        </Routes>
    );
}

export default PublicRoutes;
