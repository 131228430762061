import React, { useEffect } from 'react';
import './App.scss';

import { newTracker } from '@snowplow/browser-tracker';

import Navbar from './components/navbar/Navbar';
import PublicRoutes from './routes/PublicRoutes';
import Footer from './components/footer/Footer';

const App = () => {

  useEffect(() => {
    newTracker(`solarhero-${process.env.REACT_APP_ENV}`, 'https://sp.accelerare.cloud', {
      appId: 'solar-website',
      postPath: '/zmcvnoqwiuehi',
    });
  }, []);

  return (
    <div className="app">
      {<Navbar />}
      <div className='app-content'>
        <PublicRoutes />
      </div>
      {<Footer />}
    </div>
  );
}

export default App;